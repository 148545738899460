.icons {
    .ri {
        font-size: $font-size-xxl;
    }

    .icons__item__value {
        font-size: $font-size-xxxl;
        margin: 10px 0 5px 0;
        font-weight: bold;
    }

    .icons__item__heading {
        margin: 10px 0 5px 0;
        font-weight: bold;
    }

    .icons__item__desc {
        font-size: $font-size-xs;
        color: $text-light;
    }
}