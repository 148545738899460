.banner {
    .banner__item {
        .banner__overlay {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: $blue;
            display: flex;
            align-items: flex-end;
            padding: 50px;

            .banner__content {
                max-width: 700px;
                z-index: 999;
                color: $white-100;

                .banner__content__heading {
                    font-size: $font-size-hero;
                }
            }
        }
    }

    &.banner-top {
        .banner__item {
            .banner__overlay {
                align-items: flex-start;
            }
        }
    }

    &.banner-middle {
        .banner__item {
            .banner__overlay {
                align-items: center;
            }
        }
    }

    &.banner-right {
        .banner__item {
            .banner__overlay {
                justify-content: flex-end;

                .banner__content {
                    text-align: right;
                }
            }
        }
    }

    &.banner-center {
        .banner__item {
            .banner__overlay {
                justify-content: center;

                .banner__content {
                    text-align: center;
                }
            }
        }
    }
}

.static_banner {
    // height: 100vh;
    background-color: $white-100;
    position: relative;
    // background-image: url('../../../public/img/circles.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $overlay-05;
    padding-top: 80px;
    // background-image: url('../../../public/img/colored-gradients.png');

    .hero-stats {
        padding: 40px;
        background-color: $base;
        background-image: url('../../../public/img/circles.png');
        max-width: 100%;
        border-radius: 30px;

        * {
            color: $white-100 !important;
        }
    }

    .hero-links {
        margin-top: 20px;

        .hero-link {
            display: inline-block;
            margin: 5px;
            border: 1px solid $white-overlay-30;
            border-radius: 25px;
            padding: 10px 25px;
        }
    }

    &.static_banner-none {
        .static_banner__inner {
            .static_banner__inner__content {
                padding: 40px 20px 20px 20px;
            }
        }
    }

    &.static_banner-sm {
        .static_banner__inner {
            .static_banner__inner__content {
                padding: 100px 20px;
            }
        }
    }

    &.static_banner-xs {
        .static_banner__inner {
            background-image: none;

            .static_banner__inner__content {
                padding: 50px 20px;
            }
        }
    }

    .static_banner__inner {
        /* background-image: url('../../../public/img/bg-icons.png');
        background-position: center;
        background-size: 100% auto; */

        .static_banner__inner__content {
            padding: 200px 20px;
        }

        * {
            color: $text-base;
            text-align: center;
        }

        h1,
        h2,
        h3 {
            color: $base;
        }

        .shape {
            height: $font-size-hero;
        }

        .hero-text {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            max-width: 1000px;
            margin: auto;

            .shape {
                display: inline-block;
                margin: 0 10px;
            }
        }

        .hero-stats {
            max-width: 800px;
            margin: 50px auto 0 auto;
        }
    }
}

@media only screen and (max-width: 768px) {
    .static_banner {
        height: auto;

        .static_banner__inner {
            .static_banner__inner__content {
                padding: 40px 20px;
            }
        }
    }
}