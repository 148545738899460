.cards {
    display: flex;
    justify-content: center;

    &.no-border {
        .cards__item {
            border-width: 0;
        }
    }

    &.no-border {
        .cards__item {
            border: none;
        }
    }

    .cards__item {
        display: flex;
        flex-direction: column;
        height: 100%;
        border: 1px solid $overlay-10;
        border-radius: $border-radius;

        .cards__item__icon {
            position: relative;
            background-color: $blue;
            border-radius: inherit;
            text-align: center;
            padding-top: 100%;

            .ri {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                display: flex;
                font-size: $font-size-hero-lg;
                color: $white-100 !important;
                justify-content: center;
                align-items: center;
            }
        }

        .cards__item__img {
            &.cards__item__img-xs {
                padding: 30px 10px;
                text-align: center;

                img {
                    height: 70px;
                }
            }
        }

        .cards__item__header {
            padding: 10px;
            border-radius: $border-radius $border-radius 0 0;
            // display: flex;
            // justify-content: space-between;
            // align-items: center;

            .badge {
                margin-left: 20px;
                white-space: pre;
            }
        }

        .cards__item__subheader {
            padding: 0 10px;
            color: inherit;
        }

        .cards__item__content {
            padding: 10px;
            display: flex;
            flex-direction: column;
            margin: 10px 0;

            ol,
            ul {
                margin: 0;
                padding-left: 20px;
                font-size: $font-size-sm;

                li {
                    line-height: 150%;
                }
            }
        }

        .cards__item__footer {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            padding: 10px;
            border-top: 1px solid $overlay-10;
            margin-top: 10px;
            padding-top: 10px;
            color: $text-light;


            .cards__item__footer__logo {
                max-height: 25px;
                margin: 5px;
                margin-right: 0;
            }
        }
    }
}

.container-dark {
    .cards {
        .cards__item {
            border-color: 1px solid $overlay-10;
        }
    }
}