.bg-base {
    background-color: $base !important;
}

.color-base {
    color: $base !important;
}

.color-white {
    color: $white-100 !important;
}

.color-black {
    color: $black-100 !important;
}

.color-bcpl {
    color: $bcpl !important;
}

.color-bcpl-dark {
    color: $bcpl-dark !important;
}

.color-bcpl-darker {
    color: $bcpl-darker !important;
}

.color-yellow {
    color: $yellow !important;
}

.color-link-light {
    color: $text-link-light !important;
}