$grid-columns: 12;
$gutter-width: 15px;
$gutter-mobile-width: 5px;

@mixin calculate-column-width($columns) {
    flex-basis: percentage(($columns / $grid-columns));
    max-width: percentage(($columns / $grid-columns));
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter-width;
    margin-right: -$gutter-width;
}

.col {
    flex: 1;
    padding-left: $gutter-width;
    padding-right: $gutter-width;
}

@for $i from 1 through $grid-columns {
    .col-#{$i} {
        @include calculate-column-width($i);
        padding-left: $gutter-width;
        padding-right: $gutter-width;
    }
}

$breakpoints: (
    xs: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);

@each $breakpoint,
$width in $breakpoints {
    @media (max-width: $width) {
        @for $i from 1 through $grid-columns {
            .col-#{$breakpoint}-#{$i} {
                @include calculate-column-width($i);
                padding-left: $gutter-width;
                padding-right: $gutter-width;
            }
        }
    }
}