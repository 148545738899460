.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $overlay-10;
    padding: 5px;   
    background-color: $base;
    color: $white-100;

    /* &.scrolled {
        background-color: $base;
        color: $white-100;
    } */
}