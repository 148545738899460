.badge {
    border-radius: $border-radius-sm;
    transition: all 0.3s;
    background-color: $overlay-purple;
    color: $purple;
    padding: 7px;
    font-size: $font-size-xs;
    font-weight: bold;

    .ri {
        margin-left: 5px;
    }
}