.faqs {
    .faqs__item {
        border-top: 1px solid $overlay-10;
        border-bottom: 1px solid $overlay-10;
        padding: 20px;

        .faqs__item__question {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
        }
    }
}