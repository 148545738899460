.d-flex {
    display: flex;
    width: 100%;

    &.flex-column {
        flex-direction: column;
    }

    &.flex-row {
        flex-direction: row;
    }

}

.d-block {
    display: block;
}

.order-0 {
    order: 0;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

$justify: start, end, center, space-between, space-around;
$align: start, end, center, baseline, stretch;

@each $item in $justify {
    .justify-content-#{$item} {
        justify-content: $item;
    }
}

@each $item in $align {
    .align-items-#{$item} {
        align-items: $item;
    }
}

$spacing-values: 0, 1, 2, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100;
$spacing-type: (
    "m": "margin",
    "p": "padding"
);
$spacing-sides: (
    "t": "top",
    "r": "right",
    "b": "bottom",
    "l": "left"
);

@each $size in $spacing-values {

    @each $side,
    $side-name in $spacing-sides {

        @each $type,
        $prop in $spacing-type {
            .#{$type}-#{$size} {
                #{$prop}: #{$size}px !important;
            }

            .#{$type}#{$side}-#{$size} {
                #{$prop}-#{$side-name}: #{$size}px !important;
            }

            .#{$type}x-#{$size} {
                #{$prop}-left: #{$size}px !important;
                #{$prop}-right: #{$size}px !important;
            }

            .#{$type}y-#{$size} {
                #{$prop}-top: #{$size}px !important;
                #{$prop}-bottom: #{$size}px !important;
            }
        }
    }
}

@media only screen and (max-width:768px) {

    .order-1,
    .order-1,
    .order-2,
    .order-3 {
        order: 0;
    }
}