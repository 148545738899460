*,
*::before,
*::after {
    box-sizing: border-box;
    line-height: 130%;
}


body {
    display: flex;
    flex-direction: column;
    margin: 0;
    font-family: 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $font-size-base;
    font-weight: 300;
}

ul {
    margin: 0;
    padding: 0;

    li {
        margin: 0.5rem 0;
    }
}

ol {
    counter-reset: item;

    li {
        margin: 0.5rem 0;
    }
}


ol>li {
    counter-increment: item;
}

ol ol>li {
    display: block;
}

ol ol>li:before {
    content: counters(item, ".") ". ";
    margin-left: -20px;
}

.container {
    position: relative;
    max-width: 1200px;
    margin: auto;
    padding: 50px;

    &.container-lg {
        max-width: 1000px;
    }

    &.container-md {
        max-width: 900px;
    }

    &.container-sm {
        max-width: 700px;
    }

    &.container-unpadded {
        padding: 0;
    }
}

img {
    max-width: 100%;

    &.rounded {
        border-radius: $border-radius;
    }
}

.separator {
    display: block;
    height: 1px;
    background-color: $overlay-10;
}


.mobile-only {
    display: none;
}


@media only screen and (max-width:768px) {
    .container {
        padding: 20px;
    }

    .desktop-only {
        display: none;
    }

    .mobile-only {
        display: flex !important;
    }
}