.fs-lg {
    font-size: $font-size-lg;
}

.fs-base {
    font-size: $font-size-base;
}

.fs-sm {
    font-size: $font-size-sm;
}

.fs-xs {
    font-size: $font-size-xs;
}

a {
    display: inline-block;
    text-decoration: none;
    color: $text-link;

    &[target="_blank"] {
        &::after {
            font-family: remixicon !important;
            display: inline-block;
            content: "\ea70";
        }
    }

    * {
        color: inherit !important;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 10px 0;
    font-weight: 300;
}

h1,
h2,
h3 {
    letter-spacing: -1px;
    color: $base;
}

.h1,
h1 {
    font-size: $font-size-hero-lg;
}

.h2,
h2 {
    font-size: $font-size-hero;
}

.h3,
h3 {
    font-size: $font-size-xxxl;
}

.h4,
h4 {
    font-size: $font-size-xxl;
}

.h5,
h5 {
    font-size: $font-size-xl;
}

.h6,
h6 {
    font-size: $font-size-base;
}

.p,
p {
    line-height: 200%;

    .ri {
        margin-right: 5px;
    }
}

.text-center {
    text-align: center;

    p {
        text-align: center;
    }
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

@media only screen and (max-width:768px) {

    .h1,
    h1 {
        font-size: $font-size-hero;
    }

    .h2,
    h2 {
        font-size: $font-size-xxxl;
    }

    .h3,
    h3 {
        font-size: $font-size-xxl;
    }

    .h4,
    h4 {
        font-size: $font-size-xl;
    }

    .h5,
    h5 {
        font-size: $font-size-base;
    }

    .h6,
    h6 {
        font-size: $font-size-sm;
    }
}