$base: #ed1b25;

$purple: #514CFF;

$text-base: #060714;
$text-light: #7780A1;
$text-link: #06c;
$text-link-light: #0dcaf0;

$bcpl: #8CDF49;
$bcpl-dark: #81b03f;
$bcpl-darker: #0d2b1a;

$blue: #1145dc !default;
$indigo: #6610f2 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

$blue-light: #d9eefe !default;


$black-100: #000;
$black-90: #1a1a1a;
$black-80: #333;
$black-70: #4d4d4d;
$black-60: #666;
$black-50: #808080;
$black-40: #999;

$white-100: #fff;
$white-90: #e6e6e6;
$white-80: #ccc;
$white-70: #b3b3b3;

$overlay-purple: #eae9fa;
$overlay-90: rgba(0, 0, 0, 0.9);
$overlay-80: rgba(0, 0, 0, 0.8);
$overlay-70: rgba(0, 0, 0, 0.7);
$overlay-60: rgba(0, 0, 0, 0.6);
$overlay-50: rgba(0, 0, 0, 0.5);
$overlay-40: rgba(0, 0, 0, 0.4);
$overlay-30: rgba(0, 0, 0, 0.3);
$overlay-20: rgba(0, 0, 0, 0.2);
$overlay-10: rgba(0, 0, 0, 0.1);
$overlay-08: rgba(0, 0, 0, 0.08);
$overlay-05: rgba(0, 0, 0, 0.05);
$overlay-03: rgba(0, 0, 0, 0.03);

$white-overlay-90: rgba(255, 255, 255, 0.9);
$white-overlay-80: rgba(255, 255, 255, 0.8);
$white-overlay-70: rgba(255, 255, 255, 0.7);
$white-overlay-60: rgba(255, 255, 255, 0.6);
$white-overlay-50: rgba(255, 255, 255, 0.5);
$white-overlay-40: rgba(255, 255, 255, 0.4);
$white-overlay-30: rgba(255, 255, 255, 0.3);
$white-overlay-20: rgba(255, 255, 255, 0.2);
$white-overlay-10: rgba(255, 255, 255, 0.1);
$white-overlay-05: rgba(255, 255, 255, 0.05);

$border-radius: 0.675rem;
$border-radius-sm: 0.375rem;
$border-radius-rounded: 10rem;

$font-size-xs: 0.75rem;
$font-size-sm: 0.875rem;
$font-size-base: 1rem;
$font-size-lg: 1.125rem;
$font-size-xl: 1.25rem;
$font-size-xxl: 1.5rem;
$font-size-xxxl: 1.875rem;
$font-size-hero: 2.75rem;
$font-size-hero-lg: 3.75rem;
$font-size-hero-xl: 5rem;
$font-size-hero-xxl: 6rem;
$font-size-hero-xxxl: 7rem;