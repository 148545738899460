.form {
    .form__control {
        display: block;
        width: 100%;
        border: 1px solid $overlay-20;
        margin: 15px 0;
        padding: 15px;
        border-radius: $border-radius;
        font-size: $font-size-base;
    }
}

.form__control,
button {
    outline: none;
    border: none;
    background: transparent;
}

.hero-form {
    display: inline-flex;
    margin-top: 20px;
    border-radius: $border-radius;
    background-color: $white-100 !important;

    .form__control {
        min-width: 500px;
        padding: 20px;
        text-align: left;
        color: $text-base !important;
        border-radius: $border-radius 0 0 $border-radius;
    }

    .icon-btn {
        padding: 0 20px;

        .ri {
            color: $base;
            font-size: $font-size-lg;
        }
    }
}